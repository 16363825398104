<template>
  <div class="newplan-card" @click="goTo ? $store.dispatch('goTo', goTo) : null">
    <div class="newplan-card-inside">
      <Icon :name="icon" class="newplan-icon" />
      <div><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['goTo', 'icon']
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.newplan-card {
  background: $col-lighter-gray-font;
  margin: 20px;
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newplan-card-inside {
  @extend .secondary-text;
  padding: 30px;
}

.newplan-icon {
  @extend .icon-40;
  @extend .icon-green;
  fill: $col-font-green;
  --stroke-width: 1.6rem;
  background: white;
  border-radius: 60px;
  text-align: center;
  position: relative;
  margin: 0 auto 15px auto;
  padding: 4px;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
}
</style>
